import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	icon: {
		flexShrink: 0,
	},
	input: {
		':focus': {
			outline: 'none',
		},
		border: 0,
		left: 0,
		margin: 0,
		opacity: 0,
		padding: 0,
		position: 'absolute',
		top: 0,
		visibility: 'collapse',
	},
	label: {
		fontSize: '14px',
		marginLeft: 10,
		textAlign: 'left',
	},
	radioButton: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		position: 'relative',
	},
});
