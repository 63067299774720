import { css } from 'aphrodite';
import * as React from 'react';
import { brandPrimary } from '../../styles/colors';
import { SvgIcon } from '../svgs/icons/SvgIcon';
import { styleSheet } from './styles';

interface IProps extends React.AllHTMLAttributes<HTMLInputElement> {
	checkedFillColor?: string;
	checkedStrokeColor?: string;
	className?: string;
	iconClassName?: string;
	id?: string;
	inputClassName?: string;
	labelClassName?: string;
	type?: 'small' | 'large';
	uncheckedStrokeColor?: string;
}

export const RadioButton: React.FC<IProps> = props => {
	const {
		children,
		className,
		iconClassName = '',
		id,
		inputClassName,
		labelClassName = '',
		type,
		uncheckedStrokeColor,
		checkedFillColor,
		checkedStrokeColor,
		...restProps
	} = props;
	const size = type === 'large' ? 20 : 12;
	return (
		<label htmlFor={id} className={`radio-button ${css(styleSheet.radioButton)} ${className || ''}`}>
			<input {...restProps} className={`${css(styleSheet.input)} ${inputClassName || ''}`} id={id} type='radio' />
			<SvgIcon className={`${css(styleSheet.icon)} ${iconClassName}`} height={size} width={size}>
				<g fill='none'>
					{!!props.checked && <circle cx='6' cy='6' r='4' fill={checkedFillColor || brandPrimary} />}
					<circle
						cx='6'
						cy='6'
						r='5.5'
						stroke={props.checked ? checkedStrokeColor || brandPrimary : uncheckedStrokeColor || '#666666'}
						strokeWidth={1}
					/>
				</g>
			</SvgIcon>
			{!!children && <div className={`${css(styleSheet.label)} ${labelClassName}`}>{children}</div>}
		</label>
	);
};
