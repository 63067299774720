import * as React from 'react';
import './styles.less';

export interface ITextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	autoFocus?: boolean;
	allowAutoComplete?: boolean;
	className?: string;
	inputClassName?: string;
	inputId?: string;
	inputRef?: React.MutableRefObject<HTMLElement> | React.LegacyRef<HTMLElement>;
	leftAccessory?: React.ReactNode;
	rightAccessory?: React.ReactNode;
	type: string;
}

export const TextInput: React.FC<ITextInputProps> = props => {
	const {
		autoFocus = false,
		className,
		inputClassName,
		type,
		inputId,
		leftAccessory,
		rightAccessory,
		inputRef,
		id,
		...restProps
	} = props;
	let inRef: HTMLInputElement;
	const onRef = (input?: HTMLInputElement) => {
		// @ts-ignore
		inRef = input;

		if (typeof inputRef === 'function') {
			// @ts-ignore
			inputRef(input);
		} else if (inputRef != null) {
			// @ts-ignore
			(inputRef as React.MutableRefObject<HTMLInputElement>).current = input;
		}
	};

	const onPaddingClicked = (e: React.MouseEvent<HTMLDivElement>) => {
		if (inRef) {
			inRef.focus();
		}

		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<div className={`text-input ${className || ''}`} id={id} onClick={onPaddingClicked}>
			{leftAccessory}
			<input
				{...restProps}
				autoComplete={props.allowAutoComplete ? 'on' : 'off'}
				autoFocus={autoFocus}
				className={inputClassName}
				id={inputId}
				// @ts-ignore
				ref={onRef}
				type={type}
			/>
			{rightAccessory}
		</div>
	);
};
